import React from 'react';
import moment from 'moment';
import { fetchConnector, FetchPNLAnalyticsPropsFromRedux } from '../dataFetcher';
import { productMap, ProductMeta } from '../../common/products';
import { SimpleTableColumnConfig, SimpleTableRow, TableSize } from '../../tables/types';
import { exposuresNumFormatter } from '../../common/exposures';
import { redOnly } from '../../containers/pivotHelper';
import ExpandableTable, { ExpandableTableConfig } from '../../tables/ExpandableTable';
import { UniqueTableName } from '../../common/uniqueTableName';
import { AggFuncName } from '../../common/cellRangeAgg';
import { PNLAnalyticsEndpoint, PNLFetchStatus } from '../../actions/pnlAnalytics';
import { PNLGoodiesNonCompletedResponses } from '../PaperPlaceHolder';
import { ExposuresDataResp } from '../fetchTypes';
import { makeStyles } from '@material-ui/core/styles';

type Props = FetchPNLAnalyticsPropsFromRedux & {};

const useStyles = makeStyles(() => ({
    productBorders: {
        borderRight: '#e0e0e0 solid 1px',
    },
}));

const pivotColumns: Array<SimpleTableColumnConfig> = [
    { name: 'strategy', title: 'Strategy' },
    { name: 'deal', title: 'Deal' },
    {
        name: 'term_month_date',
        title: 'Term',
        bodyTemplate: (val: any) => {
            if (val === '') return val;
            return moment(val).format('MMM YY');
        },
    },
];

const productColumns = [
    'exposure_display|FEI|Paper',
    'exposure_display|FEI|Physical',
    'exposure_display|LST|Paper',
    'exposure_display|LST|Physical',
    'exposure_display|EPC|Paper',
    'exposure_display|EPC|Physical',
    'exposure_display|EPC C4|Paper',
    'exposure_display|EPC C4|Physical',
    'exposure_display|ARA|Paper',
    'exposure_display|ARA|Physical',
    'exposure_display|CP|Paper',
    'exposure_display|CP|Physical',
    'exposure_display|CP C4|Paper',
    'exposure_display|CP C4|Physical',
    'exposure_display|BLPG|Paper',
    'exposure_display|BLPG|Physical',
    'exposure_display|BLPG3|Paper',
    'exposure_display|BLPG3|Physical',
    'exposure_display|WTI|Paper',
    'exposure_display|BRT|Paper',
    'exposure_display|PETRO_CHEM',
];
const productsColumnConfig: Array<SimpleTableColumnConfig> = productColumns.map((productKindCombo) => {
    let productName = productKindCombo.split('|')[1];
    let tradeKind = productKindCombo.split('|')[2];
    let productMeta: ProductMeta = productMap.get(productName)!;
    let title = tradeKind === undefined ? productMeta.title : `${productMeta.title} ${tradeKind}`;
    return {
        name: productKindCombo,
        title: title,
        bodyTemplate: exposuresNumFormatter,
        textColor: redOnly,
        headerBackgroundColor: productMeta.backgroundColor,
        headerTextColor: productMeta.textColor,
        hoverElement: (row: SimpleTableRow, rawVal: any) => {
            if (rawVal === 0 || rawVal === '0' || Math.floor(Math.abs(rawVal)) === 0) return '';
            let productKey = tradeKind === undefined ? `volume_display|${productName}` : `volume_display|${productName}|${tradeKind}`;
            let volumeDisplay = exposuresNumFormatter(row[`${productKey}`] as number | undefined);
            return (
                <p>
                    Volume: <strong>{volumeDisplay}</strong>
                </p>
            );
        },
    };
});

const allColumns = pivotColumns.concat(productsColumnConfig);

const config: ExpandableTableConfig = {
    uniqueTableName: UniqueTableName.ExpDetailPhysBookPerProductKind,
    title: 'Physical Book Detail Exposures',
    size: TableSize.Small,
    columns: allColumns,
    grandTotal: true,
    aggFormatVal: (aggFuncName: AggFuncName, val: number) => {
        return aggFuncName === AggFuncName.Count ? val : exposuresNumFormatter(val);
    },
    pivotDepth: 2,
    defaultExpandable: 0,
    stickyHeader: true,
};

const DetailExpPerTradeKind = (props: Props) => {
    const classes = useStyles();
    const exposureData = props.pnlAnalytics.exposures;

    if (exposureData.status !== PNLFetchStatus.COMPLETED) {
        return <PNLGoodiesNonCompletedResponses endpoint={PNLAnalyticsEndpoint.Exposures} data={exposureData} minHeight={'462px'} />;
    }

    let respData = exposureData.data! as ExposuresDataResp;

    // Hard code the products lines that JT want
    config.columns[2].headerCellClassName = classes.productBorders;
    config.columns[2].bodyCellsClassName = classes.productBorders;
    config.columns[4].headerCellClassName = classes.productBorders;
    config.columns[4].bodyCellsClassName = classes.productBorders;
    config.columns[6].headerCellClassName = classes.productBorders;
    config.columns[6].bodyCellsClassName = classes.productBorders;
    config.columns[8].headerCellClassName = classes.productBorders;
    config.columns[8].bodyCellsClassName = classes.productBorders;
    config.columns[10].headerCellClassName = classes.productBorders;
    config.columns[10].bodyCellsClassName = classes.productBorders;
    config.columns[12].headerCellClassName = classes.productBorders;
    config.columns[12].bodyCellsClassName = classes.productBorders;
    config.columns[14].headerCellClassName = classes.productBorders;
    config.columns[14].bodyCellsClassName = classes.productBorders;
    config.columns[16].headerCellClassName = classes.productBorders;
    config.columns[16].bodyCellsClassName = classes.productBorders;
    config.columns[18].headerCellClassName = classes.productBorders;
    config.columns[18].bodyCellsClassName = classes.productBorders;
    config.columns[20].headerCellClassName = classes.productBorders;
    config.columns[20].bodyCellsClassName = classes.productBorders;
    config.columns[21].bodyCellsClassName = classes.productBorders;
    config.columns[22].bodyCellsClassName = classes.productBorders;

    return <ExpandableTable data={respData.detail_exp_phys_book_per_trade_kind} config={config} />;
};

export default fetchConnector(DetailExpPerTradeKind);
