import React, { useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { bAccFetchConnector, FetchBackAccAnalyticsPropsFromRedux } from '../dataFetcher';
import { FilterDates } from '../common/FilterDates';
import AccPNLPhysDiffComp from './AccPNLPhysDiff';
import { prepareData } from '../../pnlAnalytics/dataFetcher';
import { PNLAnalyticsEndpoint } from '../../actions/pnlAnalytics';
import { swissUseSelector } from '../../reducers';
import { BackAccountingFiltersState } from '../../reducers/backAccounting';
import EditLotReconcile from './EditLotReconcile';
import styled from 'styled-components';
import moment from 'moment-timezone';

const StyledContainer = styled.div`
    width: 100%;
`;

const Title = styled.div`
    display: flex;
    justify-content: center;
    margin: 24px 0px;
    font-size: 18px;
    font-weight: 500;
`;

type Props = FetchBackAccAnalyticsPropsFromRedux & RouteComponentProps & {};

const SCGPhysical = (props: Props) => {
    const bAFilterState = swissUseSelector<BackAccountingFiltersState>((state) => state.backAccounting);
    useEffect(() => {
        prepareData({
            ...props,
            endpoint: PNLAnalyticsEndpoint.BAccountingSCGPhysical,
            params: {
                startDate: bAFilterState.startDate!.format('YYYY-MM-DD'),
                endDate: bAFilterState.endDate!.format('YYYY-MM-DD'),
                tradeDate: bAFilterState.tradeDate!.format('YYYY-MM-DD'),
                includeAdjProj: bAFilterState.includeAdjProj,
            },
        });
    }, [props, bAFilterState]);

    return (
        <StyledContainer>
            <FilterDates />
            <Title>
                {props.pnlAnalytics.back_accounting_scg_physical?.status === 'COMPLETED' &&
                    `LTD Accounting - Traders LOTs PNL Acc: ${moment(
                        props.pnlAnalytics.back_accounting_scg_physical.data.ltd_acc_min_date
                    ).format('YYYY-MM-DD')} -> ${moment(props.pnlAnalytics.back_accounting_scg_physical.data.ltd_acc_max_date).format(
                        'YYYY-MM-DD'
                    )} Trade: ${moment(props.pnlAnalytics.back_accounting_scg_physical.data.ltd_latest_trade_date).format('YYYY-MM-DD')}`}
            </Title>
            {/*<DiffSummary {...props} />*/}
            <AccPNLPhysDiffComp {...props} tableDataKey={'ltd_lots_df'} />
            {/*<AccPNLPhysDiffComp*/}
            {/*    {...props}*/}
            {/*    uniqueTableName={UniqueTableName.SCGPhysRecDiff}*/}
            {/*    title={'LTD Traders vs Accounting LOTs PNL'}*/}
            {/*    tableDataKey={'ltd_lots_df'}*/}
            {/*/>*/}
            {/*<FlatPhysRec {...props} />*/}
            {/*<PhysRecSummary {...props} />*/}
            {/*<TradersAccMgmtReport {...props} />*/}
            {/*<PastProductsReconTable {...props} />*/}
            <EditLotReconcile />
        </StyledContainer>
    );
};

export default bAccFetchConnector(SCGPhysical);
