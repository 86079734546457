import React, { useEffect } from 'react';
import { Grid } from '@material-ui/core';
import Overview from '../common/Overview';
import ClearerBalanceTable from './ClearerBalanceTable';
import MPerStrategyPhysSummaryTable from './MPerStrategyPhysSummary';
import PerStrategyCorpSummary from '../common/PerStrategyCorpSummary';
import ExpiredLiveTable from './ExpiredLiveTable';
import { PNLMetaDataResp } from '../fetchTypes';
import { fetchConnector, FetchPNLAnalyticsPropsFromRedux, prepareData } from '../dataFetcher';
import { PNLAnalyticsEndpoint } from '../../actions/pnlAnalytics';
import { MIN_3_12_EL_WIDTH, MIN_5_12_EL_WIDTH, MIN_6_12_EL_WIDTH, MIN_7_12_EL_WIDTH } from '../../common/common';
import AdjPNLTable from './AdjPNLTable';
import ClearerHistGraph from './ClearerHistGraph';
import PNLGraph from './PNLGraph';
import VARGraph from './VARGraph';
import DrawdownGraph from './DrawdownGraph';
import PnlSharpeGraph from './PnlSharpeGraph';
import PNLBookMetricsTable from './PNLBookMetricsTable';

type Props = FetchPNLAnalyticsPropsFromRedux & {};

const ManagementIndex = (props: Props) => {
    let metaData = props.pnlAnalytics.meta.data! as PNLMetaDataResp;

    useEffect(() => {
        prepareData({
            ...props,
            endpoint: PNLAnalyticsEndpoint.AllTradesRunDate,
            urlPostFix: `/date/${metaData.selected.pipe_run_date}`,
        });
    }, [props, metaData.selected.pipe_run_date]);

    useEffect(() => {
        prepareData({
            ...props,
            endpoint: PNLAnalyticsEndpoint.ClearerRunDate,
            urlPostFix: `/date/${metaData.selected.pipe_run_date}`,
        });
    }, [props, metaData.selected.pipe_run_date]);

    useEffect(() => {
        prepareData({
            ...props,
            endpoint: PNLAnalyticsEndpoint.HistoricalPNLPerBookMetrics,
            urlPostFix: `/date/${metaData.selected.pipe_run_date}`,
        });
    }, [props, metaData.selected.pipe_run_date]);

    useEffect(() => {
        prepareData({
            ...props,
            endpoint: PNLAnalyticsEndpoint.Exposures,
            urlPostFix: `/date/${metaData.selected.pipe_run_date}`,
        });
    }, [props, metaData.selected.pipe_run_date]);

    return (
        <Grid container spacing={3} justify="center">
            <Grid item xs={7} style={{ backgroundColor: 'clear', minWidth: MIN_7_12_EL_WIDTH }}>
                <Overview />
            </Grid>
            <Grid item xs={3} style={{ backgroundColor: 'clear', minWidth: MIN_3_12_EL_WIDTH }}>
                <ClearerBalanceTable />
            </Grid>
            <Grid item xs={11} style={{ backgroundColor: 'clear', minWidth: MIN_7_12_EL_WIDTH }}>
                <ExpiredLiveTable />
            </Grid>
            <Grid item xs={7} style={{ backgroundColor: 'clear', minWidth: MIN_7_12_EL_WIDTH }}>
                <MPerStrategyPhysSummaryTable />
            </Grid>
            <Grid item xs={5} style={{ backgroundColor: 'clear', minWidth: MIN_5_12_EL_WIDTH }}>
                <PerStrategyCorpSummary />
            </Grid>
            <Grid item xs={6} style={{ backgroundColor: 'clear', minWidth: MIN_6_12_EL_WIDTH }}>
                <PNLGraph />
            </Grid>
            <Grid item xs={6} style={{ backgroundColor: 'clear', minWidth: MIN_6_12_EL_WIDTH }}>
                <VARGraph />
            </Grid>
            <Grid item xs={6} style={{ backgroundColor: 'clear', minWidth: MIN_6_12_EL_WIDTH }}>
                <DrawdownGraph />
            </Grid>
            <Grid item xs={6} style={{ backgroundColor: 'clear', minWidth: MIN_6_12_EL_WIDTH }}>
                <PnlSharpeGraph />
            </Grid>
            <Grid item xs={6} style={{ backgroundColor: 'clear', minWidth: MIN_6_12_EL_WIDTH }}>
                <ClearerHistGraph />
            </Grid>
            <Grid item xs={6} style={{ backgroundColor: 'clear', minWidth: MIN_6_12_EL_WIDTH }}>
                <PNLBookMetricsTable />
                <div style={{ marginTop: '25px' }}>
                    <AdjPNLTable />
                </div>
            </Grid>
            {/* <Grid item xs={6} style={{ backgroundColor: 'clear', minWidth: MIN_6_12_EL_WIDTH }}>
                <OpenPnlLastYearWired />
            </Grid> */}
        </Grid>
    );
};

export default fetchConnector(ManagementIndex);
