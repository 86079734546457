import React from 'react';
import { fetchConnector, FetchPNLAnalyticsPropsFromRedux } from '../../dataFetcher';
import { PNLAnalyticsEndpoint, PNLFetchStatus } from '../../../actions/pnlAnalytics';
import { PNLGoodiesNonCompletedResponses } from '../../PaperPlaceHolder';
import { STANDARD_GRAPH_ELEMENT_HEIGHT } from '../../../common/common';
import {
    P_ARA,
    P_BLPG,
    P_BLPG3,
    P_CP,
    P_EPC_C4,
    P_FEI,
    P_FEI_C4,
    productMap,
    ProductMeta,
    VP_MTB,
    VP_NAPHTHA,
    VP_PETRO_CHEM,
} from '../../../common/products';
import { numberFormat } from '../../../containers/pivotHelper';
import moment from 'moment';
import LineGraph, { LineGraphConfig } from '../../../containers/LineGraph';

type Props = FetchPNLAnalyticsPropsFromRedux & {
    title: string;
    data: Array<any>;
};

const productLines = [P_FEI, P_FEI_C4, P_CP, P_ARA, P_EPC_C4, VP_MTB, VP_NAPHTHA, P_BLPG, P_BLPG3, VP_PETRO_CHEM];
let lineConfig: LineGraphConfig = {
    title: '',
    dataKey: 'term_month_date',
    oyLabel: '',
    oxLabel: '',
    referenceLines: [{ y: 0, label: '', stroke: 'black' }],
    xTooltipFormatter: (d) => moment(d).format('MMM YY'),
    xAxisFormatter: (d) => moment(d).format('MMM YY'),
    yAxis: {
        yLimit: ['dataMin', 'dataMax'],
        yAxisFormatter: (v) => numberFormat(v, 0, false, false),
    },
    yTooltipFormatter: (v) => numberFormat(v, 0, false, false),
    chartsSettings: productLines.map((productName) => {
        let productMeta: ProductMeta = productMap.get(productName)!;
        return {
            key: productName,
            title: productMeta.title,
            color: productMeta.backgroundColor,
            chartType: productMeta.chartType!,
            stackId: productMeta.stackId,
            fillOpacity: productMeta.fillOpacity,
            strokeDasharray: productMeta.strokeDasharray,
        };
    }),
};

const Graph = (props: Props) => {
    const exposureData = props.pnlAnalytics.exposures;

    if (exposureData.status !== PNLFetchStatus.COMPLETED) {
        return (
            <PNLGoodiesNonCompletedResponses
                endpoint={PNLAnalyticsEndpoint.Exposures}
                data={exposureData}
                minHeight={STANDARD_GRAPH_ELEMENT_HEIGHT}
            />
        );
    }

    return <LineGraph {...lineConfig} values={props.data} title={props.title} />;
};

export default fetchConnector(Graph);
