import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../reducers';
import { clearAllDataAction, fetchCompletedAction, fetchFailedAction, fetchLoadingAction } from '../actions/pnlAnalytics';
import { siteErrorOpenAction } from '../actions/siteAlerts';

export interface QuickDate {
    start_date: string;
    end_date: string;
    trade_date: string;
    is_locked: boolean;
}

export interface AccQuickDate {
    company: string;
    year: number;
    period: string;
    start_date: string;
    end_date: string;
    cmp_start_date: string;
    cmp_end_date: string;
    key: string;
}

export interface BAccMetaData {
    start_date: string;
    end_date: string;
    last_entry_datetime: string;
    last_sync_datetime: string;
    valid_trade_dates: Array<string>;
    trade_date: string;
    last_accounting_locked_date: string;
    swiss_year: number;
    include_adj_proj: boolean;
    reverse_prev_year: boolean;
    quick_dates: Array<QuickDate>;
    default_quick_date_idx: number;
    valid_cash_planning_dates: Array<string>;
}

const mapState = (state: RootState) => ({
    pnlAnalytics: state.pnlAnalytics,
    authUser: state.authUser,
});

const mapDispatch = {
    fetchLoadingAction: fetchLoadingAction,
    fetchCompletedAction: fetchCompletedAction,
    fetchFailedAction: fetchFailedAction,
    siteErrorOpenAction: siteErrorOpenAction,
    clearAllDataAction: clearAllDataAction,
};

export const bAccFetchConnector = connect(mapState, mapDispatch);
export type FetchBackAccAnalyticsPropsFromRedux = ConnectedProps<typeof bAccFetchConnector>;

export type SCGPhysicalResp = {
    min_rounding_diff_ok: number;

    ltd_latest_trade_date: string;
    ltd_acc_min_date: string;
    ltd_acc_max_date: string;

    closed_lots: Array<any>;
    ongoing_lots: Array<any>;
    outside_lots: Array<any>;

    // DELETE BELLOW!!! THEY ARE OLD!!!!

    scg_rec_group_mapping: { [key: string]: Array<string> };
    scg_rec_physical_flat: Array<any>;
    scg_rec_physical_diff: Array<any>;
    scg_rec_summary: Array<any>;
    // max_scg_rec_physical_diff: number;

    invalid_danaos_ytd_lots: Array<string>;
    invalid_trade_ytd_lots: Array<string>;
    expired_danaos_ytd_lots: Array<string>;
    expired_trade_ytd_lots: Array<string>;
    future_danaos_ytd_lots: Array<string>;
    future_trade_ytd_lots: Array<string>;
    accidental_removed_pre_lots: Array<string>;
    mgmt_summary: Array<any>;
    past_products_acc_pnl_recon: Array<any>;
};
