import React from 'react';
import { fetchConnector, FetchPNLAnalyticsPropsFromRedux } from '../../dataFetcher';
import { PNLAnalyticsEndpoint, PNLFetchStatus } from '../../../actions/pnlAnalytics';
import { PNLGoodiesNonCompletedResponses } from '../../PaperPlaceHolder';
import { ExposureRows } from '../../fetchTypes';
import { SimpleTableColumnConfig, SimpleTableConfig, TableSize } from '../../../tables/types';
import SimpleTable from '../../../tables/SimpleTable';
import { kFormatterAggWithSign } from '../common';
import { UniqueTableName } from '../../../common/uniqueTableName';
import {
    P_ARA,
    P_BLPG,
    P_BLPG3,
    P_CP,
    P_CP_C4,
    P_EPC_C4,
    P_FEI,
    P_FEI_C4,
    productMap,
    ProductMeta,
    VP_CRUDE,
    VP_MTB,
    VP_NAPHTHA,
    VP_PETRO_CHEM,
} from '../../../common/products';
import { exposuresNumFormatter } from '../../../common/exposures';
import { redOnly } from '../../../containers/pivotHelper';
import moment from 'moment';

type Props = FetchPNLAnalyticsPropsFromRedux & {
    uniqueTableName: UniqueTableName;
    title: string;
    data: Array<ExposureRows>;
    defaultTableHeight: string;
};

let config: SimpleTableConfig = {
    uniqueTableName: UniqueTableName.ExpCorp, // Placeholder will change
    title: '',
    size: TableSize.Small,
    columns: [
        {
            name: 'term_month_date',
            title: 'Term',
            bodyTemplate: (val: any) => {
                if (val === 'Total') return val;
                return moment(val).format('MMM YY');
            },
        },
    ],
    grandTotal: true,
    aggFormatVal: kFormatterAggWithSign,
    pivotDepth: 0,
};

const productColumns = [P_FEI, P_FEI_C4, P_CP, P_CP_C4, P_ARA, VP_MTB, P_EPC_C4, VP_NAPHTHA, P_BLPG, P_BLPG3, VP_CRUDE, VP_PETRO_CHEM];
const productsColumnConfig: Array<SimpleTableColumnConfig> = productColumns.map((productName) => {
    let productMeta: ProductMeta = productMap.get(productName)!;
    return {
        name: productName,
        title: productMeta.title,
        bodyTemplate: exposuresNumFormatter,
        textColor: redOnly,
        headerBackgroundColor: productMeta.backgroundColor,
        headerTextColor: productMeta.textColor,
    };
});
config.columns = config.columns.concat(productsColumnConfig);

const Table = (props: Props) => {
    const exposureData = props.pnlAnalytics.exposures;

    if (exposureData.status !== PNLFetchStatus.COMPLETED) {
        return (
            <PNLGoodiesNonCompletedResponses
                endpoint={PNLAnalyticsEndpoint.Exposures}
                data={exposureData}
                minHeight={props.defaultTableHeight}
            />
        );
    }

    let thisConfig = { ...config, uniqueTableName: props.uniqueTableName, title: props.title };

    return <SimpleTable data={props.data} config={thisConfig} />;
};

export default fetchConnector(Table);
